
#KeypadGrid {
  display:inline-grid;
  width:20vw;
  height:32vh;
  grid-template-columns: auto auto auto;
  grid-template-rows: 6vh 6vh 6vh 6vh
}

#KeypadLeft{
  width:5vw;
  line-height:25vh;
  text-align:center;
  font-size:3.5vw;
  height:100vh;
  float:left;
}

.KeyPadBlock{
  background-color:#dcdcdc;
  border-radius:.4rem;
  font-size:4vh;
  text-align:center;
  margin:.5rem;
  margin-top:0vh;
  margin-bottom:1.25vh;
  line-height:4.5vh;
}