.search-filter{
  display:flex;
  margin-bottom:1em;
  align-items:center;
}
.search-filter .bar-contain {
  position:relative;
  flex:1;
  width:100%;
}

.clear-button{
  background-color:transparent
}
.search-filter .search-bar{
  display:flex;
  border: 1px solid rgba(34,36,38,.15);
  border-radius:.28571429rem;
}
.search-filter ul.search-contain li.input {
  flex: 1 1;
  margin-left: .4em;
}
.search-filter .search-bar .search-button {
  background-color:transparent!important;
  border-right:1px solid rgba(34,36,38,.15);
  border-radius:0;
  margin-left:.15em!important
}
.search-filter ul.search-contain li {
  padding: .33em 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-filter ul.search-contain{
  margin: 0;
  padding: .33em .5em;
  line-height: 1.21428571em;
  color: rgba(0,0,0,.87);
  display: flex;
  width: 100%;
  overflow-x: hidden;
}

.search-filter ul.search-contain li.searchable {
  background-color: rgba(34,36,38,.15);
  padding: 0 .02em 0 .4em;
  border-radius: .28571429rem;
  margin-right: .5em;
  color: rgba(0,0,0,.87);
  display: flex;
  align-items: center;
}

.search-filter ul.search-contain li.input {
  flex: 1;
  margin-left: .4em;

}

.search-filter ul.search-contain li.input div {
  width: 100%;
}
.ui.transparent.input>input {
  border-color: transparent!important;
  background-color: transparent!important;
  padding: 0!important;
  box-shadow: none!important;
  border-radius: 0!important;
}
.ui.input>input {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;
}

.search-filter .suggestions {
  position: absolute;
  top: 2.75rem;
  margin: 0px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 20;
  overflow-y: auto;
  max-height: 50vh;
  box-shadow: rgb(219, 210, 219) -1px 15px 35px 5px;
}
li.input::marker {
  color:transparent;
}
.suggestions button{    
    padding: 1.25em 1.75em;
    color: rgba(0,0,0,.87);
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    outline: none;
    cursor: pointer;
}
.searchable .clear-button{border:0px solid transparent}
.search-filter button{background-color:transparent;border-color:transparent;}
.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.search-filter .suggestions button:hover {
  opacity: .8;
  background-color: rgba(34,36,38,.15);
}