.MINICalendar .p-datepicker table td > span {
  width:1.75em !important;
  height:1.75em !important;
}
.p-calendar .p-component .p-inputwrapper{
  z-index:90000;
  background-color: red;
  position:relative;
}
.p-datepicker {
  z-index:1000000 !important
}
.job-card header .job-info {
  display: flex;
  flex-direction: column;
}
.job-card .job-card-info {
  display: flex;
  flex-direction: column;
  max-width: 60%;
}
.job-card footer .due-date {
  font-size: .7rem;
  color: #dcdcdc;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: .15rem .105rem .125rem .25rem;
  float:right;
}
.job-card footer .view-job {
  color: #407fe7;
  font-size: .8rem;
  padding: 0;
  border: none;
  background: transparent;
}

.job-card .item {
  font-size:1.5vh;
}
.job-card {
  font-size:1em;
  position: relative;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 2.5px 10px rgba(141,148,152,.15);
  padding: .25rem;
  margin-bottom: .5rem;
  background: #fff;
  z-index:200;
}
.job-card:active {
  opacity: 1;
  background-color:whitesmoke;
}
.job-info .line-number {
  font-size: .875rem;
  font-weight: 700;
  margin-bottom: .5rem;
}
.placeholderJob {
  width:100%;
  height:12vh;
  margin-top:2%;
  margin-bottom:2%;
  padding: 1.25rem;
  border-radius:.5rem;
  z-index:10;
  position:relative;
  background-color:whitesmoke;
}

.doesntlookgoodzoomedout{
  margin:1.5%;
  padding:0%;
  box-sizing:border-box;
  font-size:1em;
}

.JobStyling {
  margin:0px;
  margin-left:3%;
}
.PO {
  padding-top:3%
}
#unscheduled > .job-card {
  font-size:.8em;
}
.MiniDate {
  margin-right:2%;
  border:1px solid gray;
  background-color: whitesmoke;
  float:right;
  padding:0%;
}
.ProgressBar{
  width:85%;
  height:4vh;
  background-color:mediumaquamarine;
  margin-left:5%;
  margin-right:10%;
}

.ColumnContainer {
    display: inline-block;
    height: 86vh;
    overflow-y: hidden;
}
.Column {
  background-color: white;
  border-right: 1px solid #DCDCDC;
  border-radius: 0;
  height: 100%;
  width:12.4vw;
  padding: .3rem .3rem;
  margin: 0;
  display:inline-block;
  overflow-y:scroll;
  white-space:normal;
}

.Column::-webkit-scrollbar{
  display:none;
}
.p-inputtext-sm{
  width:10% important
}

.info-container {
  grid-template-columns: unset;
  width: 100%;
  padding: 1.5rem;
  border-radius: .5rem;
  background-color: #f7f7f8;
  display: grid;
  grid-column-gap: 1rem;
}
.job-card .job-tags {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 3rem;
  right: 1rem;
  width: 35%;
}
.card-tag {
  color: #fff;
  padding: .3rem .5rem .25rem;
  border-radius: 3px;
  font-size: .675rem;
  font-weight: 400;
  margin-bottom:2%;
  white-space: break-spaces;
  line-height: .875rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#LeftWindow {
  width:25%;
  float:left;
  height: calc(100% - 5rem);
  padding: 1.75rem;
  border-right: 1px solid #dcdcdc;
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  position:relative
  
}
#RightWindow{
  width:75%;
  float:left;
  margin:0px;
}

.subwindow{
  margin-left:5%;
  margin-right:5%;
  background-color:whitesmoke;
}
.subwindowpart{
  margin-bottom:0px;
  margin-top:0px;
}

.removeleftmargin{
  padding-inline-start:0px !important;
}

.JobInfo .p-dialog-header {
  padding:1% !important;
  border-bottom:6px solid #dcdcdc
}



.SORTBYSLIDER {
  float:right;
  margin-left:.5vw;
  margin-right:.5vw;
  width:4vw !important;
  height:2.7vh !important;
  margin-top:.25vh;
}

.SORTBYSLIDER .p-inputswitch-slider {
  background-color:lightslategray !important;
}
.SORTBYSLIDER .p-inputswitch-slider:before {
  width:2vh !important;
  height:2vh !important;
  margin-top:-1.05vh !important
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(2.65vw) !important
}





